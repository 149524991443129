import React from "react"
import ReactWOW from "react-wow"
import { useStaticQuery, graphql } from "gatsby"
const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          portalUrlRegister
        }
      }
    }
  `)
  return (
    <div className="landing-page-banner pg-banner">
      <div className="container">
        <div className="align-items-center d-flex justify-content-around justify-content-md-start">
          <div className="landing-pg-banner-content pg-banner-content desktop-banner-content">
            <ReactWOW delay=".1s" animation="fadeInLeft">
              <h1 className="heading-1 pb-2">
                Intelligence that reveals the hidden value in any business
              </h1>
            </ReactWOW>

            <ReactWOW delay=".1s" animation="fadeInLeft">
              <h1 className="text-1">
                Intangible assets drive value and outperformance – yet often
                remain hidden.
              </h1>
            </ReactWOW>

            <ReactWOW delay=".1s" animation="fadeInLeft">
              <div className="py-4">
                <p>
                  The Hyfen8™ platform excels at quantifying the previously
                  unmeasurable, and predicting future business performance,
                  resilience and strategic success.
                </p>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
